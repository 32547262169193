<template>
  <v-dialog
    v-model="bank"
    persistent
    max-width="610px"
    scrollable
    content-class="content-scroll"
  >
    <v-card tile>
      <v-card-title class="ma-0 d-flex justify-space-between">
        <span 
          class="font-weight-bold primary--text"
          :class="{'text-h4': $vuetify.breakpoint.smAndUp, 'text-h5': $vuetify.breakpoint.xsOnly,}"
        >
          Gestión de Banco
        </span>
        <v-btn icon plain @click="cerrar()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-overlay
        :value="loadingBan || loading"
        color="white"
        absolute
        opacity=".8"
      >
        <v-progress-circular
          indeterminate
          size="64"
          color="primary"
        ></v-progress-circular>
      </v-overlay>
      <v-card-text>    
        <validation-observer ref="ADMIN_BANK_FORM" tag="div">   
          <v-row>
            <v-col cols="12" sm="7" md="8" lg="8" class="pt-2 pb-0">
              <label-form text="Nombre" required />
              <validation-provider name="Nombre" vid="bancoInfo.nombre" rules="required" v-slot="{ errors }">
                <v-text-field
                  :value="data.nombre"
                  v-model="bancoInfo.nombre"
                  outlined
                  dense
                  :error-messages="errors[0]"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="5" md="4" lg="4" class="pt-2 pb-0">
              <label-form text="Moneda" required/>
              <validation-provider name="Moneda" vid="bancoInfo.moneda" rules="required" v-slot="{ errors }">
                <v-select
                v-model="bancoInfo.moneda"
                :value="data.moneda"
                :items="[{name: 'bolivares'},{name:'dolares'}]"
                item-text="name"
                item-value="name"
                dense
                outlined
                clearable
                :error-messages="errors[0]"
                :menu-props="{ bottom: true, offsetY: true }"
              />
              </validation-provider>
            </v-col>      
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <label-form text="Titular" required/>
              <validation-provider name="Titular" vid="bancoInfo.titular" rules="required" v-slot="{ errors }">
                <v-text-field
                  :value="data.titular"
                  v-model="bancoInfo.titular"
                  outlined
                  dense
                  :error-messages="errors[0]"
                />
               </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <label-form text="Descripción" required/>
              <validation-provider name="Descripcion" vid="bancoInfo.descripcion" rules="required" v-slot="{ errors }">
                <v-textarea
                  :value="data.descripcion"
                  v-model="bancoInfo.descripcion"
                  outlined
                  dense
                  rows="2"
                  :error-messages="errors[0]"
                />
               </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="7" md="8" lg="8" class="py-0">
              <label-form text="Número de Cuenta" required/>
              <validation-provider name="Número de Cuenta" vid="bancoInfo.cuenta" rules="required" v-slot="{ errors }">
                <v-text-field
                  v-model="bancoInfo.cuenta"
                  :value="data.cuenta"
                  outlined
                  dense
                  :error-messages="errors[0]"
                />
               </validation-provider>
            </v-col>
            <v-col cols="12" sm="5" md="4" lg="4" class="py-0">
              <label-form text="RIF" required/>
              <validation-provider name="RIF" vid="bancoInfo.rif" rules="required" v-slot="{ errors }">
                <v-text-field
                  v-model="bancoInfo.rif"
                  :value="data.rif"
                  outlined
                  dense
                  :error-messages="errors[0]"
                />
               </validation-provider>
            </v-col>
          </v-row>        
          <v-row>
            <v-col cols="12" class="mb-0 py-0">
              <v-list
                three-line
                class="pa-0 ma-0"
              >
                <v-list-item class="pa-0 ma-0">
                  <v-list-item-action class="">
                    <v-switch
                      v-model="bancoInfo.status"
                      color="secondary"
                      hide-details
                    ></v-switch>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title class="display font-weight-bold pb-0">Habilitar Banco</v-list-item-title>
                    <v-list-item-subtitle class="pt-0">Opción para habilitar o deshabilitar un Banco.</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </validation-observer>        
      </v-card-text>
      <v-card-actions class="d-flex justify-end mb-3">
        <v-btn
          tile
          depressed
          color="blue-grey lighten-5"
          class="px-8 mx-2"
          :small="$vuetify.breakpoint.mobile"
          @click="cerrar()"
        >
          Cancelar
        </v-btn>
        <v-btn
          tile
          depressed
          color="primary"
          class="px-8"
          :small="$vuetify.breakpoint.mobile"
          @click="actionBanco"
        >
          <v-icon dark left>mdi-pencil</v-icon>
          {{action === 'editar' ? 'Actualizar' : 'Crear Banco'}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
const dataDefault = () => ({
  nombre: '',
  cuenta: '',
  moneda: '',
  titular: '',
  rif: '',
  descripcion: '',
  status: true,
});
export default {
  name:'ModalBanco',
  props:{
    value: Boolean,
    data:{
      type: Object,
      default: () => ({}),
    },
    loading:{
      type: Boolean,
      default: false
    },
    action: {
      type: String,
      default: 'editar',
    },
  },
  data() {
    return {
      bank: this.value,
      loadingBan: false,
      bancoInfo: dataDefault(),     
    }
  },
  watch: {
    bank (val, oldVal) {
      if (val === oldVal) return

      this.$emit('input', val)
    },
    value (val, oldVal) {
      if (val === oldVal) return

      this.bank = val
    },
    data(val) {
      if(Object.values(val).length > 0) {
        this.bancoInfo = {...val }
      }
      else
        this.bancoInfo = dataDefault();     
    }
  },
  methods: {
    cerrar() {
      this.bank = false;
      this.bancoInfo = dataDefault();
      this.$refs.ADMIN_BANK_FORM.reset();
    },
    async actionBanco() {
      const valid = await this.$refs.ADMIN_BANK_FORM.validate();
      if(valid) {
        try {
          this.loadingBan = true;         
          const { data } = await this.$api({
            method: this.action === 'crear' ? 'post' : 'put',
            url: this.action === 'crear' ? 'administracion/bancos' : 'administracion/bancos/' + this.bancoInfo.id,
            data: this.bancoInfo,
          });
          this.cerrar();
          this.$emit('procesado');
          this.$root.$showAlert(data.message, 'success');
        } catch (error) {
            this.$root.$showAlert(
              'Lo sentimos, hubo un error al intentar realizar esta acción en el Servidor.',
              'error'
            );
        } finally {
          this.loadingBan = false;
        }
      }
    },
  },
}
</script>
