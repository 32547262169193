var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"610px","scrollable":"","content-class":"content-scroll"},model:{value:(_vm.bank),callback:function ($$v) {_vm.bank=$$v},expression:"bank"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',{staticClass:"ma-0 d-flex justify-space-between"},[_c('span',{staticClass:"font-weight-bold primary--text",class:{'text-h4': _vm.$vuetify.breakpoint.smAndUp, 'text-h5': _vm.$vuetify.breakpoint.xsOnly,}},[_vm._v(" Gestión de Banco ")]),_c('v-btn',{attrs:{"icon":"","plain":""},on:{"click":function($event){return _vm.cerrar()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-overlay',{attrs:{"value":_vm.loadingBan || _vm.loading,"color":"white","absolute":"","opacity":".8"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64","color":"primary"}})],1),_c('v-card-text',[_c('validation-observer',{ref:"ADMIN_BANK_FORM",attrs:{"tag":"div"}},[_c('v-row',[_c('v-col',{staticClass:"pt-2 pb-0",attrs:{"cols":"12","sm":"7","md":"8","lg":"8"}},[_c('label-form',{attrs:{"text":"Nombre","required":""}}),_c('validation-provider',{attrs:{"name":"Nombre","vid":"bancoInfo.nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.data.nombre,"outlined":"","dense":"","error-messages":errors[0]},model:{value:(_vm.bancoInfo.nombre),callback:function ($$v) {_vm.$set(_vm.bancoInfo, "nombre", $$v)},expression:"bancoInfo.nombre"}})]}}])})],1),_c('v-col',{staticClass:"pt-2 pb-0",attrs:{"cols":"12","sm":"5","md":"4","lg":"4"}},[_c('label-form',{attrs:{"text":"Moneda","required":""}}),_c('validation-provider',{attrs:{"name":"Moneda","vid":"bancoInfo.moneda","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"value":_vm.data.moneda,"items":[{name: 'bolivares'},{name:'dolares'}],"item-text":"name","item-value":"name","dense":"","outlined":"","clearable":"","error-messages":errors[0],"menu-props":{ bottom: true, offsetY: true }},model:{value:(_vm.bancoInfo.moneda),callback:function ($$v) {_vm.$set(_vm.bancoInfo, "moneda", $$v)},expression:"bancoInfo.moneda"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('label-form',{attrs:{"text":"Titular","required":""}}),_c('validation-provider',{attrs:{"name":"Titular","vid":"bancoInfo.titular","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.data.titular,"outlined":"","dense":"","error-messages":errors[0]},model:{value:(_vm.bancoInfo.titular),callback:function ($$v) {_vm.$set(_vm.bancoInfo, "titular", $$v)},expression:"bancoInfo.titular"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('label-form',{attrs:{"text":"Descripción","required":""}}),_c('validation-provider',{attrs:{"name":"Descripcion","vid":"bancoInfo.descripcion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"value":_vm.data.descripcion,"outlined":"","dense":"","rows":"2","error-messages":errors[0]},model:{value:(_vm.bancoInfo.descripcion),callback:function ($$v) {_vm.$set(_vm.bancoInfo, "descripcion", $$v)},expression:"bancoInfo.descripcion"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"7","md":"8","lg":"8"}},[_c('label-form',{attrs:{"text":"Número de Cuenta","required":""}}),_c('validation-provider',{attrs:{"name":"Número de Cuenta","vid":"bancoInfo.cuenta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.data.cuenta,"outlined":"","dense":"","error-messages":errors[0]},model:{value:(_vm.bancoInfo.cuenta),callback:function ($$v) {_vm.$set(_vm.bancoInfo, "cuenta", $$v)},expression:"bancoInfo.cuenta"}})]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"5","md":"4","lg":"4"}},[_c('label-form',{attrs:{"text":"RIF","required":""}}),_c('validation-provider',{attrs:{"name":"RIF","vid":"bancoInfo.rif","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.data.rif,"outlined":"","dense":"","error-messages":errors[0]},model:{value:(_vm.bancoInfo.rif),callback:function ($$v) {_vm.$set(_vm.bancoInfo, "rif", $$v)},expression:"bancoInfo.rif"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mb-0 py-0",attrs:{"cols":"12"}},[_c('v-list',{staticClass:"pa-0 ma-0",attrs:{"three-line":""}},[_c('v-list-item',{staticClass:"pa-0 ma-0"},[_c('v-list-item-action',{},[_c('v-switch',{attrs:{"color":"secondary","hide-details":""},model:{value:(_vm.bancoInfo.status),callback:function ($$v) {_vm.$set(_vm.bancoInfo, "status", $$v)},expression:"bancoInfo.status"}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"display font-weight-bold pb-0"},[_vm._v("Habilitar Banco")]),_c('v-list-item-subtitle',{staticClass:"pt-0"},[_vm._v("Opción para habilitar o deshabilitar un Banco.")])],1)],1)],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-end mb-3"},[_c('v-btn',{staticClass:"px-8 mx-2",attrs:{"tile":"","depressed":"","color":"blue-grey lighten-5","small":_vm.$vuetify.breakpoint.mobile},on:{"click":function($event){return _vm.cerrar()}}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticClass:"px-8",attrs:{"tile":"","depressed":"","color":"primary","small":_vm.$vuetify.breakpoint.mobile},on:{"click":_vm.actionBanco}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v("mdi-pencil")]),_vm._v(" "+_vm._s(_vm.action === 'editar' ? 'Actualizar' : 'Crear Banco')+" ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }